<template>
  <div>
    <v-row class="d-flex justify-space-between align-center mx-2">
      <page-header :title="title" :menu="menu" />
      <internal-menu :menuItems="menuItems" />
    </v-row>
    <v-snackbar
      v-model="snackbar.opened"
      :bottom="snackbar.y === 'bottom'"
      :color="snackbar.color"
      :left="snackbar.x === 'left'"
      :multi-line="snackbar.mode === 'multi-line'"
      :right="snackbar.x === 'right'"
      :timeout="snackbar.timeout"
      :top="snackbar.y === 'top'"
      :vertical="snackbar.mode === 'vertical'"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="snackbar.opened = false"
          >Ok</v-btn
        >
      </template>
    </v-snackbar>
    <v-row justify="center">
      <v-container>
        <v-card>
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-row class="d-flex justify-start align-end">
                <v-col cols="12" sm="4" class="mr-n8">
                  <v-switch
                    v-model="event.isPaid"
                    :label="
                      event.isPaid ? 'Evento Pago? SIM' : 'Evento Pago? NÃO'
                    "
                  ></v-switch>
                </v-col>
                <v-col cols="12" sm="5" class="mt-n8">
                  <span v-if="event.isPaid">Preço Por Pessoa</span>
                  <money
                    v-if="event.isPaid"
                    class="money"
                    v-model="event.unitPrice"
                    v-bind="money"
                  ></money>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete
                    outlined
                    :items="types"
                    v-model="event.typeId"
                    item-text="title"
                    item-value="id"
                    label="Tipo"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="8">
                  <v-text-field
                    outlined
                    v-model="event.title"
                    label="Título"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="3" class="mt-n4">
                  <v-text-field
                    type="date"
                    v-model="event.startDate"
                    label="Data Inicio"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="mt-n4">
                  <v-text-field
                    type="date"
                    v-model="event.endDate"
                    label="Data Encerramento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="mt-n4">
                  <v-text-field
                    type="time"
                    v-model="event.startTime"
                    label="Horário Início"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3" class="mt-n4">
                  <v-text-field
                    type="time"
                    v-model="event.endTime"
                    label="Horário Encerramento"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-n4">
                  <v-text-field
                    outlined
                    v-mask="'#####-###'"
                    label="CEP"
                    v-model="event.zipCode"
                    required
                    @change="searchAddress()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="8" class="mt-n4">
                  <v-text-field
                    outlined
                    label="Endereço"
                    v-model="event.address"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4" class="mt-n4">
                  <v-text-field
                    outlined
                    label="Bairro"
                    v-model="event.zone"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="mt-n4">
                  <v-text-field
                    outlined
                    label="Cidade"
                    v-model="event.city"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2" class="mt-n4">
                  <v-text-field
                    outlined
                    label="UF"
                    v-model="event.state"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="mt-n4">
                  <v-textarea
                    rows="3"
                    outlined
                    label="Anotações"
                    v-model="event.description"
                    required
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="!valid"
                  :loading="loadingAction"
                  width="200px"
                  color="#2ca01c"
                  dark
                  @click="validate()"
                >
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-container>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import http from "@/services/http";
import { Money } from "v-money";
import SearchAddressService from "@/services/address.service";
import InternalMenu from "@/components/core/InternalMenu.vue";
import PageHeader from "@/components/core/PageHeader.vue";

export default Vue.extend({
  name: "EventForm",
  components: { InternalMenu, PageHeader, Money },
  data: () => ({
    title: "Cadastro de Eventos",
    menuItems: [
      {
        icon: "mdi-storefront",
        title: "Acesse",
        permission: "all",
        subs: [
          {
            icon: "mdi-storeftont",
            title: "Aniversariantes",
            to: "/customers/birthdays",
            permission: "gerentor.person.customers.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Funcionários",
            to: "/base/employees",
            permission: "gerentor.person.employees.get_many",
          },
          {
            icon: "mdi-storeftont",
            title: "Fornecedores",
            to: "/base/suppliers",
            permission: "gerentor.suppliers.get_many",
          },
        ],
      },
    ],
    menu: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Eventos",
        disabled: false,
        href: "/events",
      },
      {
        text: "Cadastro de Eventos",
        disabled: true,
        href: "/members",
      },
    ],
    types: [],
    loading: false,
    loadingAction: false,
    snackbar: {
      color: "",
      mode: "",
      opened: false,
      text: "",
      timeout: 6000,
      x: null,
      y: "top",
    },
    money: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      precision: 2,
      masked: false,
    },
    maskPhone: "(##)####-####",
    maskCpf: "###.###.###-##",
    maskCnpj: "##.###.###/####-##",
    maskPhoneMobile: "(##)#####-####",
    valid: true,
    frequencies: [
      {
        id: "P",
        label: "Período Definido",
      },
      {
        id: "",
      },
    ],
    id: "",
    event: {
      title: "",
      isPaid: false,
      unitPrice: 0.0,
      description: "",
      typeId: null,
      startDate: null,
      endDate: null,
      startTime: null,
      endTime: null,
      zipCode: "",
      address: "",
      zone: "",
      city: "",
      state: "",
    },
  }),
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) this.registerOrUpdate();
    },

    async searchAddress() {
      const zipCode = this.event.zipCode.replace(/([^\d])+/gim, "");
      const res = await SearchAddressService(zipCode);
      if (res.success) {
        res.address.zipCode = this.event.zipCode;
        this.event.address = res.address.street;
        this.event.zone = res.address.zone;
        this.event.city = res.address.city;
        this.event.state = res.address.state;
      } else {
        this.snackbar.text = "Houve erro ao buscar o CEP";
        this.snackbar.color = "orange";
        this.snackbar.opened = true;
      }
    },
    getItem() {
      const id = this.$route.params.id;
      if (id) {
        this.loading = true;
        this.id = id;
        http.get(`/promise/events/${id}`).then(
          (res) => {
            const { data } = res;
            this.event = data;
            this.loading = false;
          },
          () => {
            this.loading = false;
          }
        );
      }
    },
    registerOrUpdate() {
      this.loadingAction = true;
      if (this.id) {
        http.put(`promise/events/${this.id}`, this.event).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Atualizado com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.$router.push("/events");
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Atualizar. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      } else {
        http.post("promise/events", this.event).then(
          () => {
            this.snackbar.color = "green";
            this.snackbar.text = "Registro Inserido com Sucesso!";
            this.snackbar.opened = true;
            this.loadingAction = false;
            this.$router.push("/events");
          },
          () => {
            this.snackbar.color = "red";
            this.snackbar.text = "Erro ao Inserir Registro. Tente Novamente!";
            this.snackbar.opened = true;
            this.loadingAction = false;
          }
        );
      }
    },
    resetForm() {
      this.$refs.form.reset();
    },

    getTypes() {
      this.loading = true;
      http.get("promise/event/types").then(
        (data) => {
          this.types = data.data;
          this.loading = false;
        },
        () => (this.loading = false)
      );
    },
  },
  mounted() {
    this.getTypes();
    this.getItem();
  },
});
</script>
<style>
.money {
  background-color: rgb(0, 126, 0);
  height: 55px;
  padding: 15px;
  font-weight: bolder;
  font-size: 16px;
  width: 100%;
  color: aliceblue;
  border-radius: 5px;
}
.money:focus {
  outline: none !important;
  background-color: rgb(7, 85, 7);
}
</style>
